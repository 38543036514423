let origin = "https://future.zhuneng.cn";
//提交捐赠
const postDonateUrl = `/activityApi/api/donate/donateApply`;
//我的捐赠
const myDonateUrl = `/activityApi/api/donate/myDonateList`;
//捐赠列表
const donateListUrl = `/activityApi/api/donate/donateList`;
//我的捐赠详情
const myDonateDetailUrl = `/activityApi/api/donate/myDonateDetail`;
//捐赠详情
const donateDetailUrl = `/activityApi/api/donate/donateDetail`;
//取消捐赠
const cancelUrl = `/activityApi/api/donate/myDonateCancel`;

export {
  postDonateUrl,
  myDonateUrl,
  donateListUrl,
  myDonateDetailUrl,
  donateDetailUrl,
  cancelUrl,
};
